<template>
  <div id="extract-list">
    <v-navigation-drawer
      v-if="!status"
      :value="isFilterUserSidebarActive"
      temporary
      touchless
      floating
      stateless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
      app
      :class="$vuetify.breakpoint.smAndUp ? 'rounded-tl-xl rounded-bl-xl' : null"
    >
      <v-sheet :color="isDark ? '#363351' : '#fafafa'" class="pa-3 d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-avatar size="48" color="secondary">
            <v-icon size="24" class="white--text">
              {{ icons.mdiFilter }}
            </v-icon>
          </v-avatar>
          <div class="text-h6 font-weight-medium ml-3">Filtreleme</div>
        </div>
        <v-btn v-if="false" icon x-small>
          <v-icon> {{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-sheet>

      <div>
        <v-autocomplete
          v-if="userData.role === 'PLSYR'"
          v-model="customer"
          label="Müşteri Adı"
          :items="customerItems"
          :loading="customerLoading"
          :search-input.sync="customerSearch"
          class="rounded-0"
          filled
          hide-details
          item-text="NAME1"
          item-value="CUSTOMER"
        ></v-autocomplete>

        <v-menu
          v-model="startMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Başlangıç Tarihi"
              hide-details=""
              readonly
              filled
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="startDate" no-title @input="startMenu = false"></v-date-picker>
        </v-menu>
        <v-menu
          v-model="endMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              label="Bitiş Tarihi"
              hide-details=""
              readonly
              filled
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="endDate" no-title @input="endMenu = false"></v-date-picker>
        </v-menu>
      </div>
      <template v-slot:append>
        <div class="pa-3">
          <v-btn x-large rounded block class="text-capitalize" color="secondary" @click="fetchExtracts()">
            Tamam
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <div v-if="statusPlasiyer">
      <v-row>
        <v-col>
          <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-expand-transition>
            <v-card
              v-if="status"
              :elevation="$vuetify.breakpoint.smAndDown ? '0' : '3'"
              rounded="xl"
              class="mx-auto mt-md-9"
              :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
              :max-width="$vuetify.breakpoint.smAndDown ? '92%' : 600"
            >
              <v-img
                class="white--text align-end"
                :class="$vuetify.breakpoint.smAndDown ? 'rounded-xl' : 'rounded-xl'"
                src="@/assets/images/pages/header-01.jpg"
                :height="$vuetify.breakpoint.smAndDown ? 260 : 260"
                gradient="to bottom, rgba(0,0,0,0), rgba(49,45,75,1)"
              >
                <div class="card-img--text card-img--text-type-1">
                  <div class="text-h3 font-weight-medium mb-0">Ekstre</div>
                  <p class="text-body-2 white--text font-weight-thin mb-1" v-text="msg" />
                  <v-progress-linear v-show="loading" color="white" indeterminate></v-progress-linear>
                </div>
              </v-img>
              <v-expand-transition>
                <div v-show="!loading">
                  <v-card-text class="pa-0">
                    <v-row :class="!$vuetify.breakpoint.smAndDown ? 'pt-6' : 'pt-6'">
                      <v-col cols="12" md="6" class="pl-md-9 pr-md-2 px-9">
                        <v-menu
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="computedDateFormatted1"
                              label="Bitiş Tarihi1"
                              persistent-hint
                              :prepend-icon="icons.mdiCalendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>

                          <v-date-picker
                            v-model="date1"
                            no-title
                            color="primary"
                            @input="menu1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6" class="pl-md-2 pr-md-9 px-9">
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="computedDateFormatted"
                              label="Bitiş Tarihi"
                              persistent-hint
                              :prepend-icon="icons.mdiCalendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>

                          <v-date-picker v-model="date" no-title color="primary" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <!-- <v-divider v-if="!$vuetify.breakpoint.smAndDown"></v-divider> -->
                  </v-card-text>

                  <v-card-actions class="pb-9 pt-9">
                    <v-spacer />
                    <v-btn
                      color="secondary"
                      class="text-capitalize text-body-1"
                      rounded
                      x-large
                      @click="fetchExtracts()"
                    >
                      Ara
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-expand-transition>
            </v-card>
          </v-expand-transition>
          <v-card
            v-if="!status"
            tile
            :flat="$vuetify.breakpoint.mdAndDown"
            :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
          >
            <v-toolbar
              v-if="!$vuetify.breakpoint.smAndDown"
              :color="$vuetify.breakpoint.mdAndDown ? (isDark ? '#3b355a' : 'transparent') : 'transparent'"
              flat
              :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
            >
              <v-toolbar-title class="text-h6 font-weight-medium">
                <v-icon large left>
                  {{ icons.mdiTrayFull }}
                </v-icon>
                Ekstre
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <download-excel
                v-if="!$vuetify.breakpoint.smAndDown"
                :data="json_data"
                :fields="json_fields"
                name="EKSTRA__TUKAS.xls"
              >
                <v-btn plain text small>
                  <v-icon left>
                    {{ icons.mdiDownload }}
                  </v-icon>
                  İndir
                </v-btn>
              </download-excel>

              <v-btn plain text small @click.stop="isFilterUserSidebarActive = !isFilterUserSidebarActive">
                <v-icon left>
                  {{ icons.mdiFilter }}
                </v-icon>
                Filitre
              </v-btn>
            </v-toolbar>
            <v-expansion-panels v-else v-model="panel" flat tile>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="text-body-2 font-weight-medium">
                    <v-icon left>
                      {{ icons.mdiTrayFull }}
                    </v-icon>
                    Ekstre
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0">
                  <v-divider></v-divider>
                  <v-list class="pa-0" color="grey">
                    <v-list-item @click.stop="isFilterUserSidebarActive = !isFilterUserSidebarActive">
                      <v-list-item-content>
                        <v-list-item-title>Filitre</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon :color="isFilterNumber ? 'tertiary' : null">
                          {{ icons.mdiFilter }}
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>İndir</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon>
                          {{ icons.mdiDownload }}
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- table -->
            <v-data-table
              v-model="selectedRows"
              :headers="tableColumns"
              :items="tableItems"
              :options.sync="options"
              :items-per-page="-1"
              fixed-header
              hide-default-footer
              :height="dataTableHeight"
              :loading="loading"
            >
              <!-- Text -->
              <template #[`item.DOCDATE`]="{ item }">
                <div v-if="item.DOCDATE.length > 2" class="text-caption">
                  {{ item.DOCDATE }}
                </div>
              </template>
              <template #[`item.DUEDATE`]="{ item }">
                <div v-if="item.DUEDATE.length > 2" class="text-caption">
                  {{ item.DUEDATE }}
                </div>
              </template>
              <template #[`item.FINDOCTYPE`]="{ item }">
                <v-chip v-if="item.FINDOCTYPE" label small class="text-caption font-weight-bold">
                  {{ item.FINDOCTYPE }}
                </v-chip>
              </template>
              <template #[`item.EXTINVNUM`]="{ item }">
                <div class="text-caption font-weight-bold">
                  {{ item.EXTINVNUM }}
                </div>
              </template>
              <template #[`item.LINEDESC`]="{ item }">
                <div class="text-caption">
                  {{ item.LINEDESC }}
                </div>
              </template>
              <template #[`item.CURRENCY`]="{ item }">
                <div class="text-caption font-weight-black">
                  {{ item.CURRENCY }}
                </div>
              </template>
              <template #[`item.DDEBIT`]="{ item }">
                <div class="text-caption" :class="item.DDEBIT !== '0,00' ? ' error--text' : null">
                  {{ item.DDEBIT }}
                </div>
              </template>
              <template #[`item.DCREDIT`]="{ item }">
                <div class="text-caption" :class="item.DCREDIT !== '0,00' ? ' success--text' : null">
                  {{ item.DCREDIT }}
                </div>
              </template>
              <template #[`item.DCURRBAL`]="{ item }">
                <div class="text-caption font-weight-black">
                  {{ item.DCURRBAL }}
                </div>
              </template>
              <template v-slot:footer>
                <v-divider></v-divider>
                <v-card :height="$vuetify.breakpoint.mdAndDown ? '' : 50" flat color="grey" rounded="0">
                  <div
                    v-for="(item, i) in tableFooter"
                    :key="i"
                    class="d-block d-md-flex align-center justify-space-between pa-3 pt-4"
                  >
                    <div>
                      <div class="text-caption">
                        {{ item.LINEDESC }}
                      </div>
                    </div>
                    <div class="d-flex pr-5 text-caption align-center">
                      <v-chip color="error" label small outlined>
                        {{ item.DDEBIT }}
                      </v-chip>
                      <v-chip color="success" class="mx-9" small label outlined>
                        {{ item.DCREDIT }}
                      </v-chip>
                      <div class="text-caption font-weight-black">
                        {{ item.DCURRBAL }}
                      </div>
                    </div>
                  </div>
                </v-card>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
      <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
        Lütfen yukarıdan bir müşteri seçin
      </v-alert>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { mdiDownload, mdiFilter, mdiSend, mdiTrayFull } from '@mdi/js'
import { currency } from '@utils'
import { computed, onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
import Vue from 'vue'
import extractStoreModule from './extractStoreModule'

export default {
  components: {
    CustomerList,
  },
  setup() {
    const EXTRACT_APP_STORE_MODULE_NAME = 'app-extract'
    const { isDark } = useAppConfig()
    const { router } = useRouter()
    // Register module
    if (!store.hasModule(EXTRACT_APP_STORE_MODULE_NAME)) {
      store.registerModule(EXTRACT_APP_STORE_MODULE_NAME, extractStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EXTRACT_APP_STORE_MODULE_NAME)) store.unregisterModule(EXTRACT_APP_STORE_MODULE_NAME)
    })
    const isFilterUserSidebarActive = ref(false)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const msg = ref('Formu doldurduktan sonra ara butonuna basın')
    const status = ref(true)
    const startDate = ref(
      new Date(Date.now() + new Date().getTimezoneOffset() * 60 * 60 * 60 * 100).toISOString().substr(0, 10),
    )
    const endDate = ref(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const startMenu = ref(false)
    const endMenu = ref(false)
    const tableItems = ref([])
    const tableFooter = ref([])
    const customer = ref(null)
    const customerSearch = ref(null)
    const customerItems = ref([])
    const customerLoading = ref(false)
    const tableColumns = [
      {
        text: 'Yevm. Tarih',
        value: 'DOCDATE',
        sortable: false,
        width: '100px',
      },
      {
        text: 'Vade Tarihi',
        value: 'DUEDATE',
        sortable: false,
        width: '100px',
      },
      {
        text: 'Belge Tipi',
        value: 'FINDOCTYPE',
        sortable: false,
        width: '90px',
      },
      {
        text: 'Belge No',
        value: 'EXTINVNUM',
        sortable: false,
        width: '100px',
      },
      {
        text: 'Açıklama',
        value: 'LINEDESC',
        sortable: false,
      },
      {
        text: 'Para Birimi',
        value: 'CURRENCY',
        sortable: false,
        width: '100px',
      },
      {
        text: 'Borç',
        value: 'DDEBIT',
        align: 'right',
        sortable: false,
        width: '120px',
      },

      {
        text: 'Alacak',
        value: 'DCREDIT',
        align: 'right',
        sortable: false,
        width: '120px',
      },
      {
        text: 'Bakiye',
        value: 'DCURRBAL',
        align: 'right',
        sortable: false,
        width: '120px',
      },
    ]
    const totalExtractListTable = ref(0)
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const selectedRows = ref([])

    const extracts = (items, index, array) => {
      const DOCDATE = items.DOCDATE.slice(0, 10).replaceAll(' ', '')
      const DUEDATE = items.DUEDATE.slice(0, 10).replaceAll(' ', '')
      const item = {
        DOCDATE,
        DUEDATE,
        FINDOCTYPE: items.FINDOCTYPE,
        EXTINVNUM: items.EXTINVNUM,
        LINEDESC: items.LINEDESC,
        CURRENCY: items.CURRENCY,
        DDEBIT: items.LINETYPE === '6' ? currency.format(items.RDEBIT) : currency.format(items.DDEBIT),
        DCREDIT: items.LINETYPE === '6' ? currency.format(items.RCREDIT) : currency.format(items.DCREDIT),
        DCURRBAL: items.LINETYPE === '6' ? currency.format(items.RCUMBALANCE) : currency.format(items.DCURRBAL),
      }

      if (index < array.length - 2) {
        tableItems.value.push(item)
        json_data.value.push(item)
      } else if (index === array.length - 1) {
        tableFooter.value.push(item)
      }
    }

    const statusPlasiyer = ref(false)
    onMounted(() => {
      if (store.state.secondState == true && store.state.secondMsg) {
        Vue.swal({
          title: 'Hata',
          html: store.state.secondMsg,
          icon: 'error',
          background: '#FF4C51',
          timerProgressBar: true,
          showConfirmButton: false,
        })
        router.push({ name: 'payment' })
      }

      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        statusPlasiyer.value = false
      } else {
        statusPlasiyer.value = true
      }
    })
    watch(
      () => store.state.plasiyerCustomer,
      () => {
        if (store.state.plasiyerCustomer) {
          statusPlasiyer.value = true
          status.value = true
        }
      },
    )

    const json_data = ref([])

    const fetchExtracts = () => {
      const [day, month, year] = computedDateFormatted.value.split('/')
      const newEndDate = `${year}-${month}-${day}`

      const [day1, month1, year1] = computedDateFormatted1.value.split('/')
      const newStartDate = `${year1}-${month1}-${day1}`

      isFilterUserSidebarActive.value = false
      tableItems.value = []
      tableFooter.value = []
      msg.value = 'Arama yapılıyor lütfen bekleyin'
      loading.value = true
      const fetchExtractsData = {
        method: 'getExtract',
        'search[startDate]': newStartDate,
        'search[endDate]': newEndDate,
      }
      if (userData.role === 'PLSYR') {
        //  fetchExtractsData['search[customer]'] = customer.value
      }
      store
        .dispatch('app-extract/fetchExtracts', fetchExtractsData)
        .then(response => {
          response.detail.filter(extracts)
          totalExtractListTable.value = Number(response.detail.length)
          loading.value = false
          status.value = false
        })
        .catch(error => {
          console.log(error)
        })
    }

    watch([customerSearch], val => {
      customerLoading.value = true
      store
        .dispatch('app/fetchCustomersBySalDept', val)
        .then(response => {
          customerItems.value = response.detail
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          customerLoading.value = false
        })
    })

    const date = ref(new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10))
    const date1 = ref(new Date(new Date().setDate(new Date().getDate() - 45)).toISOString().substr(0, 10))
    const menu1 = ref(false)
    const menu2 = ref(false)

    const formatDate = dates => {
      if (!dates) return null
      const [year, month, day] = dates.split('-')

      return `${day}/${month}/${year}`
    }

    let dateFormatted = formatDate(new Date().toISOString().substr(0, 10))

    const parseDate = dates => {
      if (!dates) return null
      const [month, day, year] = dates.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }

    const computedDateFormatted = computed(() => formatDate(date.value))
    const computedDateFormatted1 = computed(() => formatDate(date1.value))

    watch(date, () => {
      dateFormatted = formatDate(date.value)
    })

    return {
      menu1,
      menu2,
      date,
      date1,
      dateFormatted,
      computedDateFormatted,
      computedDateFormatted1,
      parseDate,
      formatDate,
      customerItems,
      customerSearch,
      customerLoading,
      customer,
      isDark,
      tableColumns,
      tableItems,
      json_data,
      tableFooter,
      loading,
      selectedRows,
      options,
      startDate,
      endDate,
      startMenu,
      endMenu,
      msg,
      status,
      userData,
      totalExtractListTable,
      fetchExtracts,
      isFilterUserSidebarActive,
      statusPlasiyer,

      // icons
      icons: {
        mdiDownload,
        mdiTrayFull,
        mdiFilter,
        mdiSend,
      },
    }
  },
  data() {
    return {
      dataTableHeight: '',
      height: {
        system: 0,
        top: 95,
        footer: 51,
      },
      json_fields: {
        'Yevm. Tarih': 'DOCDATE',
        'Vade Tarihi': 'DUEDATE',
        'Belge Tipi': 'FINDOCTYPE',
        'Belge No': 'EXTINVNUM',
        Açıklama: 'LINEDESC',
        'Para Birimi': 'CURRENCY',
        Borç: 'DDEBIT',
        Alacak: 'DCREDIT',
        Bakiye: 'DCURRBAL',
      },

      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 150 - this.height.top - this.height.footer - 1
    },
  },
}
</script>
